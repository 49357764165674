import {
  SWAN_BASE_URL_MAP,
  SWAN_STYLE_KEY_MAP,
  ScreenClassProvider,
  SwanFontNameEnum,
  SwanHead,
  SwanProvider,
  getRootClassNames,
  useBrowserClasses,
} from '@vp/swan'
import { ResponsiveProvider } from 'modules/upload/contexts/useResponsiveProvider'
import * as React from 'react'
import { Helmet } from 'react-helmet'

export const SwanConfiguration: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const swanBaseUrl = SWAN_BASE_URL_MAP.default.cdn // default value, Use based on the locales
  const browserClassName = useBrowserClasses()
  const bodyClassName = getRootClassNames()
  return (
    <SwanProvider swanBaseUrl={swanBaseUrl}>
      <Helmet htmlAttributes={{ class: browserClassName }} bodyAttributes={{ class: bodyClassName }} />
      <SwanHead renderWith={Helmet} renderStyleContentAsChildren styleSheetKeys={Object.values(SWAN_STYLE_KEY_MAP)} fontNames={[SwanFontNameEnum.Graphik]} />
      <ScreenClassProvider>
        <ResponsiveProvider>{children}</ResponsiveProvider>
      </ScreenClassProvider>
    </SwanProvider>
  )
}
